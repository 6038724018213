import React from "react"

const Hero = () => {
  return (
    <section className="page-title page-title-4 bg-darkggit ">
      <div className="background-image-holder">
        <img
          alt="Background"
          className="background-image"
          src="../img/bannertalk.jpg"
        />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h1 className="hidden">Contact Us</h1>
            <h3 className="uppercase mb0 bolder white-text">TALK To Me</h3>
          </div>
        </div>
        {/* <!--end of row--> */}
      </div>
      {/* <!--end of container--> */}
    </section>
  )
}

export default Hero
